import React from 'react';
import Header from "../header/Header";
import Footer from "../footer/Footer";
import {useNavigate} from 'react-router-dom';

export default function LegalInformation() {

    const navigate = useNavigate();

    return (
        <div>
            <Header />

            <div className="container">

                <h1>
                    Rechtliche Hinweise
                </h1>

                <p>Sofern nicht ausdrücklich anders vermerkt, besitzt das Schweizerische Sozialarchiv keine Urheberrechte an den Inhalten der Periodika und ist auch nicht für deren Inhalt verantwortlich. Die Rechte liegen in der Regel bei den jeweiligen Herausgebern.</p>

                <p>Die verlinkten Publikationen stehen für nicht-kommerzielle Zwecke in Lehre und Forschung sowie für die private Nutzung zur Verfügung.</p>

                <p>Soweit es sich um die Verlinkung auf externe Angebote Dritter handelt, sind zwingend die Nutzungsbedingungen der jeweiligen Anbieter zu beachten.</p>

                <p>Insbesondere ist das Veröffentlichen in Print- und Online-Publikationen nur mit vorheriger Genehmigung der Rechteinhaber erlaubt. Die Weitergabe und Speicherung von Teilen des elektronischen Angebots auf anderen Servern bedarf ebenfalls des Einverständnisses der Rechteinhaber.</p>

                <p>Alle Angaben erfolgen ohne Gewähr für Vollständigkeit oder Richtigkeit. Es wird keine Haftung übernommen für Schäden durch die Verwendung von Informationen aus diesem Online-Angebot oder durch das Fehlen von Informationen. Dies gilt auch für Inhalte Dritter, die über dieses Angebot zugänglich sind.</p>

                <span className="link" onClick={() => navigate(-1)}>Zurück</span>
            </div>

            <Footer />
        </div>
    );
}
