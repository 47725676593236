import React, {useState} from "react";
import Contains from "../common/Contains";
import './Issues.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faLock, faUnlink} from '@fortawesome/free-solid-svg-icons'
import Modal from "../common/Modal";
import {IPeriodica} from '../common/IPerioidica';
import FetchData from "../common/FetchData";

interface IProps {
    periodica?: IPeriodica
    search: string
}

export default function Issues(props: IProps) {

    const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
    const [modalText, setModalText] = useState<string>('');

    if (!props.periodica || !props.periodica.issues) {
        return null;
    }


    const openModal = (restriction: string) => {
        setModalIsOpen(true);
        setModalText(restriction);
    };

    let collection: JSX.Element[] = [];

    for (const issue of Object.values(props.periodica.issues)) {

        if (!Contains.contains(issue.title, props.search)) {
            continue;
        }

        if (!issue.md5) {
            collection.push(
                <div key={issue.id} className="grid-item placeholder">
                    <FontAwesomeIcon icon={faUnlink} size={"3x"} color="grey"/>
                    <p className="grid-text">
                        {issue.title}
                    </p>
                </div>
            );
        } else if (issue.restriction) {
            collection.push(
                <div key={issue.id} className="grid-item locked"
                     onClick={() => openModal(issue.restriction || '')}>
                    <FontAwesomeIcon icon={faLock} size={"3x"}/>
                    <img src={FetchData.getBaseUrl() + 'thumbnail/' + issue.id + '.png?t='  + issue.thumbnailUpdated} alt={issue.title} />
                    <p className="grid-text">
                        {issue.title}
                    </p>
                </div>
            );
        } else {
            collection.push(
                <a href={FetchData.getBaseUrl() + 'pdf/' + issue.id + '.pdf'} target="_blank" rel="noopener noreferrer" key={issue.id} >
                    <div className="grid-item">
                        <img src={FetchData.getBaseUrl() + 'thumbnail/' + issue.id + '.png?t=' + issue.thumbnailUpdated} alt={issue.title} />
                        <p className="grid-text">
                            {issue.title}
                        </p>
                    </div>
                </a>
            );
        }
    }

    return (
        <>
            <div className="grid">
                {collection}
            </div>
            <Modal isOpen={modalIsOpen} text={modalText} isOpenFunc={setModalIsOpen}/>
        </>
  );
}
