import React from 'react';
import { createRoot } from 'react-dom/client';
import Router from './Router';
import './Core.css';

const container = document.getElementById('root');
if (container) {
    const root = createRoot(container);
    root.render(<Router />);
}
