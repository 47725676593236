import React from "react";
import Contains from "../common/Contains";
import {Link} from "react-router-dom";
import './List.css';
import {IPeriodica} from "../common/IPerioidica";

interface IProps {
    response: IPeriodica[];
    search: string;
}

export default function List(props: IProps) {

    const periodicals: JSX.Element[] = [];
    for (const periodical of props.response) {
        if (Contains.contains(periodical.title, props.search)) {
            const id = periodical.idAleph && periodical.idAleph > 0 ?
                periodical.idAleph :
                periodical.mmsId;
            periodicals.push(
                <Link key={id} to={'/' + id}><div>{periodical.title}</div></Link>
            );
        }
    }

    return <div className="periodica-list">{periodicals}</div>;
}
