import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faBars, faTh} from '@fortawesome/free-solid-svg-icons';
import './ChangeView.css';

interface IProps {
    view: string;
    setView: (search: string) => void;
}

export default function ChangeView(props: IProps) {

    return <div id="change-view">
        <span onClick={() => props.setView('list')}>
            <FontAwesomeIcon icon={faBars} size={"2x"} color={props.view === 'list' ? 'black' : 'lightgrey'}/>
        </span>
        <span onClick={() => props.setView('tiles')}>
            <FontAwesomeIcon icon={faTh} size={"2x"} color={props.view === 'tiles' ? 'black' : 'lightgrey'}/>
        </span>
    </div>;
};
