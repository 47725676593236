import * as React from "react";
import { BrowserRouter, Routes, Route} from "react-router-dom";
import Home from "./home/Home";
import LegalInformation from "./legalInformation/LegalInformation";
import Periodica from "./periodica/Periodica";
import Access from './access/Access';

export default function Router() {
    return <React.StrictMode>
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Home />}/>
                <Route path="/rechtliche-hinweise" element={<LegalInformation />}/>
                <Route path="/:id/zugriffsberechtigung" element={<Access />}/>
                <Route path="/:id" element={<Periodica />}/>
            </Routes>
        </BrowserRouter>
    </React.StrictMode>;
}
