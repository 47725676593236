import {IPeriodica} from "./IPerioidica";

export default class FetchData {

    static data?: IPeriodica[] = undefined;

    static getBaseUrl() {
        return 'https://e-perabt.sozialarchiv.ch/';
    }

    static async get() {

        if (this.data) {
            return this.data;
        }

        const url = this.getBaseUrl() + 'data.json';

        const res = await fetch(url, {});

        this.data = await res.json();


        return this.data;
    }

    static async getSingle(id?: string) {

        if (!id) {
            return;
        }

        const url = this.getBaseUrl() + 'data/'+id+'.json';

        const res = await fetch(url, {});

        return await res.json();
    }
}
