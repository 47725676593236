import React from "react";
import {IPeriodica} from "../common/IPerioidica";
import {Link} from "react-router-dom";

interface IProps {
    periodica: IPeriodica;
}

export default function LegalNotice(props: IProps) {

    const ownPublications = [
        '99116769253105526', // Info / Sozialarchiv Info / SozialarchivInfo
        '990108696470205526' // Jahresbericht / Schweizerisches Sozialarchiv
    ]

    if (ownPublications.includes(props.periodica.mmsId)) {
        return <p>
            Urheber dieser Publikation ist das Schweizerische Sozialarchiv. Sie wurde unter der <a href="https://creativecommons.org/licenses/by/4.0/deed.de" target="_blank" rel="noopener nofollow">Creative Commons Lizenz CC BY 4.0</a> veröffentlicht und kann gemäss deren Vorgaben verwendet werden.
        </p>;
    }

    return <p>
        Das Schweizerische Sozialarchiv besitzt keine Urheberrechte an den Inhalten der Periodika und ist auch nicht für deren Inhalt verantwortlich. Bitte beachten Sie die <Link to="/rechtliche-hinweise"> rechtlichen Hinweise</Link> zur Verwendung der Periodika.
    </p>;
}
