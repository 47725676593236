import React, {useEffect, useState} from 'react';
import Header from "../header/Header";
import Footer from "../footer/Footer";
import {IPeriodica} from '../common/IPerioidica';
import FetchData from '../common/FetchData';
import Loading from '../common/Loading';
import { useParams } from "react-router-dom";

type TParams = {
    id: string;
}

export default function Access() {

    const [response, setResponse] = useState<IPeriodica[]>([]);
    const [error, setError] = useState<string|null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    let params = useParams<TParams>();

    useEffect(() => {
        (async () => {
            try {
                setIsLoading(false);
                const json = await FetchData.get();
                if (json) {
                    setResponse(json);
                }
                setIsLoading(false);
            } catch (error: any) {
                setError(error.message);
            }
        })();
    }, []);

    if (isLoading) {
        return <Loading />;
    }

    if (error) {
        return <span>{error}</span>;
    }

    const id = parseInt(params.id ?? '');

    const periodica = response.find((p) => {
        return p.idAleph === id;
    });

    if (!periodica) {
        return null;
    }

    return (
        <div>
            <Header />

            <div className="container">

                <h1>{periodica.title} - Zugriffsberechtigung</h1>

                <p>{periodica.restriction}</p>
            </div>

            <Footer />
        </div>
    );
};
