import React, {useEffect, useState} from "react";
import Header from "../header/Header";
import Footer from "../footer/Footer";
import {IPeriodica} from "../common/IPerioidica";
import FetchData from "../common/FetchData";
import RenderRelated from "./RenderRelated";
import {useParams} from "react-router-dom";
import Issues from "./Issues";
import Search from "../search/Search";
import Loading from '../common/Loading';
import LegalNotice from "./LegalNotice";

type TParams = {
    id?: string;
}

export default function Periodica() {

    const [periodica, setPeriodica] = useState<IPeriodica>();
    const [error, setError] = useState<string|null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [search, setSearch] = useState<string>('');
    let params = useParams<TParams>();

    useEffect(() => {
        (async () => {
            try {
                setIsLoading(true);
                const json = await FetchData.getSingle(params.id);
                if (json) {
                    setPeriodica(json);
                }
                setIsLoading(false);
            } catch (error: any) {
                setError(error.message);
            }
        })();
    }, [params.id]);

    if (isLoading) {
        return <Loading />;
    }

    if (error) {
        return <span>{error}</span>;
    }

    if (!params.id) {
        return <span>error1</span>;
    }

    if (!periodica) {
        return null;
    }

    return (
        <div>
            <Header>
                <Search search={search} setSearch={setSearch}/>
            </Header>
            <h1>{periodica.title}</h1>

            <RenderRelated periodica={periodica}/>

            <Issues periodica={periodica} search={search}/>

            <LegalNotice periodica={periodica} />

            <Footer />
        </div>
  );
}
