import React, {useEffect} from "react";
import './Modal.css';

interface IProps {
    isOpen: boolean;
    text: string;
    isOpenFunc: (isOpen: boolean) => void;
}

export default function Modal(props: IProps) {


    useEffect(() =>  {

        const keyDownListener = (event: KeyboardEvent) => {
            if(props.isOpen && event.key === 'Escape') {
                props.isOpenFunc(false);
            }
        };

        document.addEventListener('keydown', keyDownListener, false);

        return () => {
            document.removeEventListener('keydown', keyDownListener, false);
        };
    }, [props]);

    if (!props.isOpen) {
        return null;
    }

    return <div className="modal">
        <div className="modal-content">
            <span className="close" onClick={() => props.isOpenFunc(false)}>&times;</span>
            <p>{props.text}</p>
        </div>

    </div>
}
