class Contains {

    static contains(a: string, b: string) {

        if (b === '') {
            return true;
        }

        a = Contains.removeDiacritics(a).toLowerCase();
        b = Contains.removeDiacritics(b).toLowerCase();
        return a.includes(b);
    }

    static removeDiacritics(input: string) {
        return input.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
    }
}

export default Contains;
