import React from 'react';
import './Header.css';
import {Link} from "react-router-dom";

interface IProps {
    children?: JSX.Element | JSX.Element[];
}


export default function Header(props: IProps) {

    return (
        <header>
            <Link to="/">
                <div className="logo" />
            </Link>

            {props.children &&
                <div className="right">{props.children}</div>
            }
        </header>
    );
}
