import React, {useState} from "react";
import Header from "../header/Header";
import Footer from "../footer/Footer";
import Search from "../search/Search";
import Items from "./Items";
import ChangeView from "./ChangeView";


export default function App() {

    const [search, setSearch] = useState<string>('');
    const [view, setView] = useState<string>(initView());

    const setView2 = function (input: string) {
        if (input === 'list' || input === 'tiles') {
            localStorage.setItem('view', input);
            setView(input);
        }
    };

    return (
        <div>
            <Header>
                <ChangeView view={view} setView={setView2}/>
                <Search search={search} setSearch={setSearch}/>
            </Header>
            <Items search={search} view={view}/>
            <Footer />
        </div>
  );
};

function initView(): string {
    if (typeof(localStorage) === "undefined") {
        return 'list';
    }

    const output = localStorage.getItem('view');

    if (output === 'tiles') {
        return 'tiles';
    }

    return 'list';
}
