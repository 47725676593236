import React, {useEffect, useState} from "react";
import FetchData from "../common/FetchData";
import {IPeriodica} from "../common/IPerioidica";
import Loading from '../common/Loading';
import List from "./List";
import Tiles from "./Tiles";

interface IProps {
    search: string;
    view: string;
}

export default function Items(props: IProps) {

    const [response, setResponse] = useState<IPeriodica[]>([]);
    const [error, setError] = useState<string|null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        (async () => {
            try {
                setIsLoading(true);
                const json = await FetchData.get();
                if (json) {
                    setResponse(json);
                }
                setIsLoading(false);
            } catch (error: any) {
                setError(error.message);
            }
        })();
    }, []);

    if (isLoading) {
        return <Loading />;
    }

    if (error) {
        return <span>{error}</span>;
    }

    if (props.view === 'list') {
        return <List search={props.search} response={response} />;
    } else {
        return <Tiles search={props.search} response={response} />;
    }
}
