import React from 'react';
import './Footer.css';
import {Link} from "react-router-dom";

const Footer: React.FC = () => {
  return (
      <footer>
          <div><Link to={"/rechtliche-hinweise"}>Rechtliche Hinweise</Link></div>
          <div className="copyright">
              © 2019 <a href="https:///www.sozialarchiv.ch" target="_blank" rel="noopener noreferrer">Schweizerisches
              Sozialarchiv</a>
          </div>
      </footer>
  );
};

export default Footer;
