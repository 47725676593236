import React from 'react';
import './Search.css';


interface IProps {
    search: string;
    setSearch: (search: string) => void;
}

export default function Search(props: IProps) {

    return <input type="search" value={props.search} onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            props.setSearch(e.currentTarget.value)} placeholder="Suchbegriff eingeben"/>;
};
