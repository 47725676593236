import React from "react";
import Contains from "../common/Contains";
import {Link} from "react-router-dom";
import {IPeriodica} from "../common/IPerioidica";
import FetchData from "../common/FetchData";

interface IProps {
    response: IPeriodica[];
    search: string;
}

export default function Tiles(props: IProps) {

    const periodicals: JSX.Element[] = [];
    for (const periodical of props.response) {
        if (Contains.contains(periodical.title, props.search)) {
            const id = periodical.idAleph && periodical.idAleph > 0 ?
                periodical.idAleph :
                periodical.mmsId;
            if (periodical.issues.length === 0) {
                periodicals.push(
                    <Link key={id} to={'/' + id}>
                        <div className="grid-item">
                            <p className="grid-text">{periodical.title}</p>
                        </div>
                    </Link>
                );
            } else {
                const thumbIssue = periodical.issues[0];
                periodicals.push(
                    <Link key={id} to={'/' + id}>
                        <div className="grid-item">
                            <img src={FetchData.getBaseUrl() + 'thumbnail/' + thumbIssue.id + '.png?t='  +
                            thumbIssue.thumbnailUpdated} alt={periodical.title} />
                            <p className="grid-text">{periodical.title}</p>
                        </div>
                    </Link>
                );
            }
        }
    }

    return <div className="grid">{periodicals}</div>;
}
