import React from "react";
import {Link} from "react-router-dom";
import {IPeriodica} from "../common/IPerioidica";
import {IRelated} from "../common/IRelated";

interface IProps {
    periodica: IPeriodica
}

export default function RenderRelated(props: IProps) {

    if (!props.periodica.precursors && !props.periodica.successors) {
        return null;
    }

    let output: JSX.Element[] = [];
    if (props.periodica.precursors) {
        output.push(<div key="precursors">Vorgänger: {getRelated(props.periodica.precursors, 'p')}</div>);
    }
    if (props.periodica.successors) {
        output.push(<div key="successors">Nachfolger: {getRelated(props.periodica.successors, 's')}</div>);
    }

    return <>{output}</>;
};

function getRelated(related: IRelated[], prefix: string): JSX.Element[] {
    let collection: JSX.Element[] = [];
    const length = related.length;
    related.forEach(function(p, i:number) {
        collection.push(<Link key={prefix + p.mmsId} to={'/' + p.mmsId}>{p.title}</Link>);
        if (i < length - 1) {
            collection.push(<span key={p + i.toString()}>, </span>);
        }
    });

    return collection;
}
